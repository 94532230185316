<template>
  <div>
    <b-form @submit.prevent="onSubmit" @reset.prevent="onReset">
      <h4>{{ $t('acc.hu') }}</h4>
      <b-form-group :label="$t('lgin.f.emaill')" label-for="accCredEmail">
        <b-input-group>
          <template #prepend>
            <b-input-group-text>
              <b-icon-at />
            </b-input-group-text>
          </template>
          <b-form-input type="email" id="accCredEmail" :state="f.email!=''" v-model="f.email" disabled />
        </b-input-group>
      </b-form-group>
      <b-form-group :label="$t('lgin.f.pwl')" label-for="accCredPassword">
        <b-input-group>
          <template #prepend>
            <b-input-group-text>
              <b-icon-lock />
            </b-input-group-text>
          </template>
          <b-form-input type="password" id="accCredPassword" :state="valPw" v-model="f.pw" pattern=".{8,}" autofocus @keydown.enter.native="onSubmit" />
        </b-input-group>
        <b-form-invalid-feedback :state="valPw">
          {{ $t('lgin.f.pwf') }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-row>
        <b-col cols="12" class="d-flex justify-content-end">
          <b-button type="reset" variant="primary" class="mr-2 mr-sm-3" id="accCredBtnReset" :disabled="!isChanged"><Icon type="r" /> {{ $t('btn.r') }}</b-button>
          <b-button type="submit" variant="primary" id="accCredBtnSubmit" :disabled="!valPw || !isChanged"><Icon type="u" /> {{ $t('btn.u') }}</b-button>
        </b-col>
      </b-form-row>
    </b-form>
  </div>
</template>

<script>
import { BIconAt, BIconLock } from 'bootstrap-vue';
import Icon from '@/components/Icon';
import { REST } from '@/components/RestCall';

export default {
  data() {
    return {
      f: {
        email: '',
        pw: ''
      }
    }
  },
  computed: {
    onlineData() {
      return this.$store.state.oUser;
    },
    valPw() {
      if (this.f.pw === '') return null;
      return this.f.pw.length >= 8 ? true : false;
    },
    isUser() {
      return this.$store.state.oLogin.isUser;
    },
    isChanged() {
      return this.f.pw != '';
    },
  },
  watch: {
    onlineData: function(data) {
      this.f.email = data.emailAddress;
    }
  },
  created() {
    REST.get('/' + (this.isUser ? 'users' : 'customers') + '/' + this.$store.getters.uuid)
        .then(resp => {
          this.$store.commit('setUser', resp.d.data);
        }).catch(e => {
          this.$store.commit('showWarn',e.message)
        })
  },
  methods: {
    onSubmit() {
      REST.put('/' + (this.isUser ? 'users' : 'customers') + '/' + this.$store.getters.uuid + '/login', {
            uuid4: this.$store.getters.uuid,
            emailAddress: this.f.email,
            password: this.f.pw
          }).then(resp => {
            this.$store.commit('showSuccess',resp.d.message);
          }).catch(e => {
            this.f.pw = '';
            this.$store.commit('showWarn',e.message);
          })
    },
    onReset() {
      this.f.pw = '';
    }
  },
  components: {
    Icon,
    BIconAt,
    BIconLock,
  },
}
</script>
